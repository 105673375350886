import React from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const AboutUs = () => {
    return (
        <Element name='aboutUs' className='about-us'>
            <div className='container'>
                <h3 className='text-center mb-4 subtitle'>Hakkımızda</h3>
                <div className='card shadow border-0'>
                    <div className='card-body p-5'>
                        <div className='row align-items-center'>
                            <div className='col-xl-6'>
                                <img src='https://img.freepik.com/free-vector/male-team-illustration_23-2150184954.jpg?w=1380&t=st=1680531016~exp=1680531616~hmac=7d0c7b0b6b157659f093ab32400375ad43d7e822d01a50255a0fe588e62379b5' width="90%" />
                            </div>
                            <div className='col-xl-6 mt-xl-0 mt-lg-0 mt-md-0 mt-3'>
                                <p className='mb-0'>
                                    Pers Bilişim Hizmetleri, bilişim hizmetleri sektöründe yenilikçi ve güvenilir çözümler sunan lider bir kuruluş olarak, yazılım geliştirme, siber güvenlik, veri analitiği ve bulut çözümleri gibi geniş bir yelpazede hizmet vermektedir. Müşterilerimizin dijital dönüşüm yolculuğunda iş süreçlerini optimize etmelerini ve verimliliklerini artırmalarını sağlamak amacıyla, alanında uzman ekibimizle en ileri teknolojik altyapıyı sunuyoruz.
                                    <br />
                                    <br />
                                    Yüksek kalite standartlarımız, müşteri odaklı yaklaşımımız ve inovasyona verdiğimiz önemle, iş ortaklarımızın güvenilir teknoloji ortağı olmaktan gurur duyuyoruz ve sürdürülebilir büyüme ile sürekli gelişim prensiplerimizle geleceğin bilişim dünyasında değer katmaya devam edeceğiz.
                                </p>
                                <Link to="services" spy={true} smooth={true} duration={0}>
                                    <button className='btn btn-services py-3 px-5 mt-xl-5 mt-4'>
                                        Hizmetlerimiz
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default AboutUs