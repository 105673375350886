import React from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const WhyUs = () => {
    return (
        <Element name='whyUs' className='why-us'>
            <div className='container'>
                <h3 className='text-center mb-4 subtitle'>Neden Pers Bilişim Hizmetleri ?</h3>
                <div className='card shadow border-0'>
                    <div className='card-body p-5'>
                        <div className='row align-items-center why-us-container'>
                            <div className='col-xl-6 mt-xl-0 mt-lg-0 mt-md-0 mt-3'>
                                <p className='mb-0'>
                                    Pers Bilişim Hizmetleri, deneyimli ve uzman ekibiyle yenilikçi, özelleştirilmiş bilişim çözümleri sunmaktadır. Müşteri memnuniyeti odaklı yaklaşımımız ve yüksek kalite standartlarımızla iş süreçlerinizi optimize ediyoruz. Teknolojik gelişmeleri takip ederek hizmetlerimizi sürekli güncelliyor ve dijital dönüşümünüzde güvenilir bir rehber olarak yanınızda yer alıyoruz.
                                </p>
                                <Link to="contact" spy={true} smooth={true} duration={0}>
                                    <button className='btn btn-services py-3 px-5 mt-xl-5 mt-4'>
                                        İletişime Geç
                                    </button>
                                </Link>
                            </div>
                            <div className='col-xl-6'>
                                <img src='https://img.freepik.com/free-vector/male-team-illustration_23-2150184954.jpg?w=1380&t=st=1680531016~exp=1680531616~hmac=7d0c7b0b6b157659f093ab32400375ad43d7e822d01a50255a0fe588e62379b5' width="90%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default WhyUs;