import React from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Banner = () => {
    return (
        <Element name='pageTop' className='banner'>
            <div className='banner-bg'></div>
            <div className='container d-flex align-items-center justify-content-center' style={{ zIndex: 2 }}>
                <div>
                    <div className='circle-1'></div>
                    <div className='circle-2'></div>
                    <div className='circle-3'></div>
                </div>
                {/* <div className='row align-items-center'> */}
                <div className='text-center w-75'>
                    <h1 className='mb-4'><span style={{color: "#ab0e09"}}>Pers</span> Bilişim Hizmetleri</h1>
                    <p className='mb-0'>
                        Pers Bilişim Hizmetleri olarak, her kurumun gereksinimlerinin farklı olduğunun farkında olarak size özgü çözümler üreterek, satış sonrası Çağrı Merkezi desteğimizle her zaman yanınızdayız.
                    </p>
                    <Link to="aboutUs" spy={true} smooth={true} duration={0}>
                        <button className='btn btn-outline-light btn-explore mt-5 py-3 px-5'>
                            Detaylı Bilgi
                        </button>
                    </Link>
                </div>
                {/* <div className='col-xl-6 banner-image'>
                        <img src='./images/bg.png' width="100%" />
                    </div> */}
                {/* </div> */}
            </div>
        </Element>
    )
}

export default Banner;