import React from 'react'
import Slider from "react-slick";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Services = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            }
        ]
    };
    return (
        <Element name='services' className='services'>
            <div className='container'>
                <h3 className='text-center mb-4 subtitle'>Hizmetlerimiz</h3>
                <Slider {...settings}>
                    <div className='card shadow border-0'>
                        <img src="./images/helpdesk.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>Yazılım Geliştirme</h5>
                            <p className='mb-0 text-truncate-3'>
                                İşletmenizin ihtiyaçlarına uygun, ölçeklenebilir ve güvenli yazılım çözümleri tasarlıyoruz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/santral.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>Siber Güvenlik</h5>
                            <p className='mb-0 text-truncate-3'>
                                Verilerinizi korumak için kapsamlı güvenlik çözümleri sunuyor ve siber tehditlere karşı sizi koruyoruz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <div className='d-flex align-items-center'>
                            <img src="./images/crm.jpg" width="75%" className='rounded' />
                        </div>
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>Veri Analitiği</h5>
                            <p className='mb-0 text-truncate-3'>
                                Büyük veri analizleri ile iş süreçlerinizi optimize ederek, daha bilinçli kararlar almanızı sağlıyoruz.
                            </p>
                        </div>
                    </div>

                    <div className='card shadow border-0'>
                        <img src="./images/security.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>Bulut Çözümleri</h5>
                            <p className='mb-0 text-truncate-3'>
                                İş yüklerinizi buluta taşıyarak, esnek ve maliyet etkin bilişim altyapıları sunuyoruz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/network.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>IT Danışmanlığı</h5>
                            <p className='mb-0 text-truncate-3'>
                                Teknoloji stratejilerinizi geliştirmek ve dijital dönüşümünüzü desteklemek için uzman danışmanlık hizmetleri veriyoruz.
                            </p>
                        </div>
                    </div>
                    <div className='card shadow border-0'>
                        <img src="./images/hosting.jpg" width="75%" className='rounded' />
                        <div className='card-body p-4' style={{height: "155px"}}>
                            <h5>Ağ Yönetimi</h5>
                            <p className='mb-0 text-truncate-3'>
                                Güvenli, hızlı ve sorunsuz ağ altyapıları kurarak, kesintisiz iletişim ve veri akışı sağlıyoruz.
                            </p>
                        </div>
                    </div>
                </Slider>
            </div>
        </Element>
    )
}

export default Services