import React, { useEffect, useState } from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { slide as Menu } from 'react-burger-menu'

const Header = () => {
    const [fixed, setFixed] = useState(false);
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 1200px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 1200px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 0) {
            setFixed(true);
        }
        else {
            setFixed(false)
        }
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {matches && (
                <div style={{ zIndex: 123 }} className={`py-4 position-fixed w-100 header ${fixed ? "header-fixed shadow" : ""}`}>
                    <div className='container'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                {/* <img src={`${!fixed ? "./images/logo/svg/logo-no-background.svg" : "./images/logo/svg/logo-no-background-black.svg"}`} width="225px" /> */}
                                <img src={`${!fixed ? "./images/logo/png/pers.png" : "./images/logo/png/pers.png"}`} width="150px" />
                            </div>
                            <div className='navbar'>
                                <ul className='d-flex list-unstyled mb-0'>
                                    <li className='me-5'>
                                        <Link activeClass="active" to="pageTop" spy={true} smooth={true} duration={0}>Ana Sayfa</Link>
                                    </li>
                                    <li className='me-5'>
                                        <Link activeClass="active" className="" to="aboutUs" spy={true} smooth={true} duration={0}>Hakkımızda</Link>
                                    </li>
                                    <li className='me-5'>
                                        <Link activeClass="active" className="" to="services" spy={true} smooth={true} duration={0}>Hizmetlerimiz</Link>
                                    </li>
                                    <li className='me-5'>
                                        <Link activeClass="active" className="" to="whyUs" spy={true} smooth={true} duration={0}>Neden Pers Bilişim Hizmetleri ?</Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" className="" to="contact" spy={true} smooth={true} duration={0}>İletişim</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!matches && (
                <div>
                    <div style={{ zIndex: 123 }} className={`py-4 bg-transparent position-fixed w-100 header ${fixed ? "header-fixed shadow" : ""}`}>
                        <div className='container'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    {/* <img src={`${!fixed ? "./images/logo/svg/logo-no-background.svg" : "./images/logo/svg/logo-no-background-black.svg"}`} width="215px" /> */}
                                    <img src={`${!fixed ? "./images/logo/png/pers.png" : "./images/logo/png/pers.png"}`} width="150px" />
                                </div>
                                <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <i className="fa-solid fa-bars"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div>
                        <div className="offcanvas-body">
                            <div className='mobile-navbar'>
                                <ul className='list-unstyled mb-0'>
                                    <li className='mb-4' data-bs-dismiss="offcanvas">
                                        <Link activeClass="active" to="pageTop" spy={true} smooth={true} duration={0}>Ana Sayfa</Link>
                                    </li>
                                    <li className='mb-4' data-bs-dismiss="offcanvas">
                                        <Link activeClass="active" className="" to="aboutUs" spy={true} smooth={true} duration={0}>Hakkımızda</Link>
                                    </li>
                                    <li className='mb-4' data-bs-dismiss="offcanvas">
                                        <Link activeClass="active" className="" to="services" spy={true} smooth={true} duration={0}>Hizmetlerimiz</Link>
                                    </li>
                                    <li className='mb-4' data-bs-dismiss="offcanvas">
                                        <Link activeClass="active" className="" to="whyUs" spy={true} smooth={true} duration={0}>Neden Pers Bilişim Hizmetleri ?</Link>
                                    </li>
                                    <li data-bs-dismiss="offcanvas">
                                        <Link activeClass="active" className="" to="contact" spy={true} smooth={true} duration={0}>İletişim</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='offcanvas-footer'>
                            © 2024 - Tüm Hakları Saklıdır - Avax Bilgisayar Yazılım Hizmetleri Danışmanlık Tic. Ltd. Şti.
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Header;